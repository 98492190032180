/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap'); */
/* h1,h2,h3,h4,h5,h6,span,p {
    font-family: 'Source Sans Pro', sans-serif !important;
} */

@font-face {
  font-family: Source Sans Pro-Light;
  src: url(./fonts/Source_Sans_Pro/SourceSansPro-Light.ttf);
}

@font-face {
  font-family: Source Sans Pro;
  src: url(./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: Source Sans Pro-Bold;
  src: url(./fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf);
}

.scrollbar-newUX::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: unset;
  background-color: white;
}
.scrollbar-newUX::-webkit-scrollbar {
  width: 8px;
  background-color: white;
}
.scrollbar-newUX::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: unset;
  background-color: #ababab;
}

#category-popover > div:nth-child(1) {
  background: #282828 !important;
  opacity: 0.56;
}

/* https://fonts.google.com/specimen/Inter#standard-styles 
   https://fonts.googleapis.com/css?family=Inter
*/

@font-face {
  font-family: Inter-Light;
  src: url(./fonts/Inter/Inter-Light-slnt=0.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter/Inter-Regular-slnt=0.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(./fonts/Inter/Inter-Bold-slnt=0.ttf);
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
label,
li,
input,
th,
td,
textarea {
  font-family: Source Sans Pro, sans-serif;
}

body {
  margin: 0;
}
body > div[role='tooltip'] {
  z-index: 1500;
}

#scrollCustom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#scrollCustom::-webkit-scrollbar-track {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

#scrollCustom::-webkit-scrollbar-track-piece {
  width: 8px;
  background: #ffffff;
}

#scrollCustom::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: hsla(0, 0%, 82%, 0.97);
}

.scrollCustomGray::-webkit-scrollbar-track-piece {
  background: #f4f6fc !important;
}

.scrollCustom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollCustom::-webkit-scrollbar-track {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.scrollCustom::-webkit-scrollbar-track-piece {
  width: 8px;
  background: #ffffff;
}

.scrollCustom::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #4e5865;
}